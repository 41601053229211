import React from 'react'
import { Typography } from '@material-ui/core'
import { useTranslation } from 'react-i18next'

import { SEO } from 'components'

function NotFoundPage() {
  const { t } = useTranslation()

  return (
    <>
      <SEO title={t('404')} />

      <Typography variant='h6'>{t('404')}</Typography>
    </>
  )
}

export default NotFoundPage
